import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]
  static values = {
    modalId: String
  }

  connect() { 
    console.log(this.modalIdValue)
  }

  toggle () {
    document.querySelector(`#${this.modalIdValue}`).classList.toggle("hidden")
  }
}
