import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  toggleCheckbox (event) {
    const checkboxes = document.querySelectorAll(".is-primary-checkbox")
    checkboxes.forEach(checkbox => {
      checkbox.checked = checkbox === event.target
    })
  }
}
