import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["button", "spinner"]
  static values = {
    url: String,
    appId: String,
    payload: String,
    method: String,
    followRedirect: Boolean
  }

  async perform () {
    this.spinnerTarget.classList.add("animate-spin")

    const payload = JSON.parse(this.payloadValue)
    const formData = new FormData()

    for (var key in payload) {
      formData.append(key, payload[key])
    }

    Rails.ajax({
      type: this.methodValue,
      url: this.urlValue,
      data: formData,
      success: () => {
        this.spinnerTarget.classList.remove("animate-spin")
      }
    })

    // const request = new FetchRequest('post', '/apps/1/connect', {
    //   body: JSON.stringify({ name: 'Request.JS' })
    // })
    // const response = await request.perform()

    // if (response.ok) {
    //   console.log("OK")
    // }
  }
}
