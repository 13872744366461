import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
  static targets = ["success", "failure"]
  static debounces = ["check"]

  connect () {
    useDebounce(this)
  }

  async check (event) {
    if (!event.target.value) return

    const response = await get(`/apps/directive_availability?directive=${event.target.value}`)
    
    if (response.ok) {
      let taken = (await response.json).taken
      this.successTarget.classList.toggle("hidden", taken)
      this.failureTarget.classList.toggle("hidden", !taken)
    }
  }
}
