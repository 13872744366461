import { Controller } from "@hotwired/stimulus"

const UP = 38
const DOWN = 40
const ENTER = 13
const NAVIGATORS = [UP, DOWN, ENTER]

export default class extends Controller {
  static targets = ["input", "reference", "results", "result", "resultTemplate"]
  static values = {
    kind: { type: String, default: "get" },
    response: { type: String, default: "json" },
    payload: { type: Object, default: {} },
    endpointUrl: String,
    destinationUrl: String,
    debounce: { type: Boolean, default: false },
    enterAction: { type: String, default: "select" }, // "select", "destination",
    displayAttribute: String
  }

  connect () {
    this.isLoading = false
    this.selectedResultIndex = -1
  }

  async fetchResults (event) {
    if (this.query === "") {
      this.clear()
      return
    }

    this.isLoading = true
    const response = await fetch(this.endpointUrlValue)

    if (response.ok) {
      this.resultsTarget.innerHTML = ""
      if (this.responseValue === "json") {
        const decodedResponse = await response.json()
        this.presentJsonResponse(decodedResponse)
      }
    } else {
      return
    }
    this.isLoading = false
  }

  navigate (event) {
    if (NAVIGATORS.includes(event.keyCode)) {
      event.keyCode === ENTER ? this.handleEnter() : this.handleDirection(event)
    } else {
      return
    }
  }

  handleEnter () {
    if (this.enterActionValue === "select") {
    } else {
    }
  }

  handleDirection (event) {
    if (event.keyCode === UP) {
      this.selectedResultIndex -= 1
    } else {
      this.selectedResultIndex += 1
    }
  }

  presentJsonResponse (response) {
    response.forEach(result => {
      let clone = this.resultTemplateTarget.content.cloneNode(true)
      clone.querySelector("li").innerHTML = result[this.displayAttributeValue]
      this.resultsTarget.appendChild(clone)
    })
    this.resultsTarget.classList.toggle("hidden", false)
  }

  clear () {
    this.resultsTarget.classList.toggle("hidden", true)
    this.resultsTarget.innerHTML = ""
  }
  
  get query () {
    return this.inputTarget.value
  }
}
