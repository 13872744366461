import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["connected", "notConnected", "connectSpinner", "disconnectSpinner", "connectButtonContent", "disconnectButtonContent"]
  static values = {
    appDirective: String
  }

  connect () {
    this.inProcess = false
  }

  async toggle (event) {
    if (this.inProcess) return
    this.inProcess = true

    const performRequest = event.detail.performRequest !== undefined ? event.detail.performRequest : true

    const spinner = event.params.connect ? this.connectSpinnerTarget : this.disconnectSpinnerTarget
    const content = event.params.connect ? this.connectButtonContentTarget : this.disconnectButtonContentTarget
    const endpoint = event.params.connect ? "connect" : "disconnect"

    spinner.classList.remove("hidden")
    content.classList.add("hidden")
    
    if (performRequest) {
      const response = await post(`/apps/${this.appDirectiveValue}/${endpoint}`, { body: JSON.stringify({}) })

      if (response.ok) {
        this.connectedTarget.classList.toggle("hidden", !event.params.connect)
        this.notConnectedTarget.classList.toggle("hidden", event.params.connect)
      }
    } else {
      this.connectedTarget.classList.remove("hidden")
      this.notConnectedTarget.classList.add("hidden")
    }

    spinner.classList.add("hidden")
    content.classList.remove("hidden")

    this.inProcess = false
  }
}
