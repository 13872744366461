import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["field", "select"]

  connect () {
    this.toggle()
  }

  toggle () {
    this.fieldTargets.forEach(field => {
      field.classList.toggle("hidden", !field.dataset.fieldScope.includes(this.selectTarget.value))
    })
  }
}
