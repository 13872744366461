import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]
  static values = {
    callback: String
  }

  keyboardClear (event) {
    if (event.code === "Escape") this.clear()
  }

  backgroundClear (event) {
    if (!this.modalTarget.contains(event.target)) this.clear()
  }

  submitEnd (event) {
    if (event.detail.success) {
      if (!!this.callbackValue && this.callbackValue === "app_connection")
        this.dispatch("app-connect-success", { detail: { performRequest: false } })
      this.clear()
    }
  }

  clear () {
    this.element.remove()
  }
}
