import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "parameterForms"]

  connect () {}
  addAssociation () {
    let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.parameterFormsTarget.insertAdjacentHTML("beforeend", content)
  }
  removeAssociation (event) {
    let wrapper = event.target.closest(".nested-fields")
    console.log(wrapper)
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy'").value = 1
      wrapper.style.display = "none"
    }
  }
}
