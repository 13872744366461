import "@hotwired/turbo-rails"
import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"
import "@fontsource-variable/inter"

import Notification from "@stimulus-components/notification"

const application = Application.start()

application.debug = process.env.NODE_ENV === "development"
application.warnings = true
window.Stimulus = application

const controllers = import.meta.glob("../**/*_controller.js", { eager: true })
registerControllers(application, controllers)

application.register("notification", Notification)

// for (let path in controllers) {
//   let module = controllers[path];
//   let name = path.match(/\.\/(.+)_controller\.js$/)[1].replaceAll("/", "--");
//   console.log(name)
//   application.register(name, module.default);
// }

// // Import all channels.
const channels = import.meta.glob("../**/*_channel.js")

//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
