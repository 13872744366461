import { Controller } from "@hotwired/stimulus"
import { pluck } from "dirty-pluck"

export default class extends Controller {
  static targets = ["expression", "input", "output"]
  connect() {}

  call() {
    const input = JSON.parse(this.inputTarget.value)
    const plucked = pluck(input, this.expressionTarget.value)
    this.outputTarget.innerHTML = JSON.stringify(plucked)
  }
}
