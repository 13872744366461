import { Controller } from "@hotwired/stimulus"
import { createSwapy } from "swapy"
import { put } from "@rails/request.js"

export default class extends Controller {
  static targets = ["container"]

  connect() {
    const container = this.hasContainerTarget ? this.containerTarget : this.element
    const items = container.querySelectorAll("[data-swapy-item]")

    this.positions = Array.from(items).map(slot => slot.dataset.swapyItem)
    this.itemId = null

    this.swapy = createSwapy(container, { animation: "dynamic" })
    this.swapy.onSwap(this.handleSwap) // Handle swap event
  }

  async release(event) {
    this.itemId = event.params.id
    const url = event.params.url
    const newItemPosition = parseInt(this.positions.findIndex(id => id === this.itemId.toString()))
    const payload = { position: newItemPosition }
    await put(url, { body: JSON.stringify(payload), responseKind: "json" })
  }

  handleSwap = event => {
    this.positions = Object.values(event.data.object)
  }
}
