import { Controller } from "@hotwired/stimulus"
import { createPopper } from '@popperjs/core';
// import tippy from 'tippy.js';
// import 'tippy.js/dist/tippy.css';


export default class extends Controller {
  static targets = ["tooltip", "reference"]
  static values = {
    content: String
  }

  connect () {
    // tippy(this.referenceTarget, {
    //   content: this.contentValue,
    //   placement: "top"
    // })
  }

  pop () {
    createPopper(this.referenceTarget, this.tooltipTarget, {
      placement: "top-end"
    })
    this.tooltipTarget.classList.toggle("hidden")
  }

}
