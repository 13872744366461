import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "filterable"]
  static classes = ["filteredOut"]

  handleInput () {
    this.filterableTargets.forEach(element => {
      if (element.dataset.filterableAttribute.toLowerCase().includes(this.inputTarget.value)) {
        element.classList.remove(this.filteredOutClass)
      } else {
        element.classList.add(this.filteredOutClass)
      }
    })
  }
}
